import { IConfigMgr } from '@nab/nab-x-sdk-core';
import {
    DafAuthConfig,
    DafLoginType,
    DafOnboardingAuthStrategy,
    DafStepUpAuthStrategy,
    DafStepUpAuthStrategyConfig,
    DafTokenRefreshStrategy,
    DafTransactionStepUpAuthStrategy,
    getShellActions,
    IDafOnboardingAuthStrategyConfig,
    IDafTokenRefreshStrategyConfig,
    IDafTransactionStepUpAuthStrategyConfig,
    IDafTransactionStepUpIdentityMiniAppConfig
} from '@nab/x-spa-react';
import {
    AppConfigs,
    BASE_CONFIG_OVERRIDES,
    CONFIG_STORAGE_KEY,
    ConfigKey,
    CustomDAFUsernamePwdStrategy,
    DafTokenMigrationStrategy,
    DafUsernamePwdIdentityMiniAppConfig,
    getLoginMiniappHostELement,
    getTransStepUpHostELement,
    ShellSeedTokenProviderWrapperStrategy,
    WLOverridingConfig
} from '@usl/usl-base-web-shell';
import { IDafTokenMigrationAuthStrategyConfig } from '@usl/usl-base-web-shell/dist/module/authStrategies';

import { NABEDGE } from '../constants';

export function getWLOverridingConfig(appConfigs: AppConfigs): WLOverridingConfig {
    const events = appConfigs.get(ConfigKey.EVENTS);

    const identityMiniAppConfig: DafUsernamePwdIdentityMiniAppConfig = {
        environment: appConfigs.get(ConfigKey.AUTH_ENVIRONMENT, 'prod'),
        tenant: appConfigs.get(ConfigKey.AUTH_TENANCY, 'wlnab'),
        clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
        scope: appConfigs.get(ConfigKey.AUTH_SCOPE),
        title: ' ',
        superTitle: ' ',
        deviceType: 'DESKTOP_BROWSER',
        contactNumber: appConfigs.get('DAF_CONTACT_NUMBER', '1300 200 445'),
        contactEmail: appConfigs.get('DAF_CONTACT_EMAIL', 'nabedgesupport@nab.com.au'),
        /**
         * In Live versions setting this value breaks the daf miniapp, so for now we will leave it for them to set. If passed undefined DAF will use the correct endpoint according to the environment
         */
        dafApiUrl:
            appConfigs.get(ConfigKey.AUTH_ENVIRONMENT) === 'local' ? appConfigs.get(ConfigKey.DAF_ENDPOINT) : undefined,
        featureFlags: {
            noPadding: true
        }
    };

    const accessTokenAuthConfig: DafAuthConfig = {
        environment: appConfigs.get(ConfigKey.AUTH_ENVIRONMENT, 'prod'),
        context: 'external',
        tokenSource: 'DAF',
        tokenType: 'Bearer',
        isSeed: true,
        appMetaData: { id: 'SHELL', appType: 'XV2App' }
    };

    const transactionStepUpAppConfig: IDafTransactionStepUpIdentityMiniAppConfig = {
        clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
        scope: appConfigs.get(ConfigKey.AUTH_SCOPE),
        tenant: appConfigs.get(ConfigKey.AUTH_TENANCY, 'wlnab'),
        environment: appConfigs.get(ConfigKey.AUTH_ENVIRONMENT, 'prod'),
        displayAsModal: true
    };

    const transactionStepUpAuthStrategyConfig: IDafTransactionStepUpAuthStrategyConfig = {
        assetManifestUrl: appConfigs.get(ConfigKey.FRAUD_TRAN_STEPUP_MANIFEST_URL),
        getHostElement: getTransStepUpHostELement,
        accessTokenAuthConfig: accessTokenAuthConfig,
        appConfig: transactionStepUpAppConfig,
        onSuccess: () => {},
        onFailure: function (error) {
            // Identity mini-app published user_logout_required event when OTP fails for 3 times
            if (error.error === 'verify_user_response_max_verification_attempts_exceeded') {
                try {
                    getShellActions().dispatchEvent('GENERAL_MODAL', {
                        modalType: 'forceLogout',
                        display: true
                    });
                } catch (error) {
                    console.error(`forceLogout error: ${error}`);
                }
            }
        }
    };

    const stepUpAuthConfig: DafStepUpAuthStrategyConfig<DafAuthConfig> = {
        assetManifestUrl: appConfigs.get(ConfigKey.FRAUD_TRAN_STEPUP_MANIFEST_URL),
        getHostElement: getTransStepUpHostELement,
        appConfig: {
            clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
            scope: appConfigs.get(ConfigKey.AUTH_SCOPE),
            tenant: appConfigs.get(ConfigKey.AUTH_TENANCY, 'wlnab'),
            environment: appConfigs.get(ConfigKey.AUTH_ENVIRONMENT, 'prod'),
            displayAsModal: true
        },
        onSuccess: () => {},
        onFailure: (error) => {}
    };

    const defaultAuthConfig = {
        context: 'external',
        tokenSource: 'DAF',
        tokenType: 'Bearer',
        grantType: undefined
    };

    const onboardingConfig: IDafOnboardingAuthStrategyConfig = {
        assetManifestUrl: appConfigs.get(ConfigKey.DAF_ASSET_MANIFEST),
        getHostElement: getTransStepUpHostELement,
        appConfig: {
            tenant: appConfigs.get('ONBOARDING_TENANT')
        },
        clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
        scopes: appConfigs.get(ConfigKey.AUTH_SCOPE),
        onSuccess: () => {
            const { updateApps } = getShellActions();
            updateApps({
                authConfig: {
                    ...defaultAuthConfig,
                    flags: {
                        loginType: DafLoginType.USER_ONBOARDING
                    }
                } as DafAuthConfig
            });
        },
        onFailure: (e) => console.error(`OnboardingAuthStrategy error: ${e}`)
    };

    const tokenRefreshConfig: IDafTokenRefreshStrategyConfig = {
        customerTenant: appConfigs.get('CUSTOMER_TENANT', 'wlnab'),
        prospectTenant: appConfigs.get('ONBOARDING_TENANT'),
        clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
        scopes: appConfigs.get(ConfigKey.AUTH_SCOPE),
        onSuccess: () => {
            const { updateApps } = getShellActions();
            updateApps({
                authConfig: {
                    ...defaultAuthConfig,
                    flags: {
                        loginType: DafLoginType.PROSPECT_REFRESH
                    }
                } as DafAuthConfig
            });
        },
        onFailure: (e) => console.error(`TokenMigrationAuthStrategy error: ${e}`)
    };

    const tokenMigrationConfig: IDafTokenMigrationAuthStrategyConfig = {
        tenant: appConfigs.get('CUSTOMER_TENANT', 'wlnab'),
        clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
        scopes: appConfigs.get(ConfigKey.AUTH_SCOPE),
        onSuccess: () => {
            const { updateApps } = getShellActions();
            updateApps({
                authConfig: {
                    ...defaultAuthConfig,
                    flags: {
                        loginType: 'UserMigration'
                    }
                } as DafAuthConfig
            });
        },
        onFailure: (e) => console.error(`TokenMigrationAuthStrategy error: ${e}`)
    };

    const configuredIdleTime = appConfigs.get(ConfigKey.IDLE_TIME);
    const configuredEarlyCheckTime = appConfigs.get(ConfigKey.EARLY_CHECK_FOR_HEARTBEAT);

    // Since all the values from config come as string we have to convert the type
    const idleTime: number =
        configuredIdleTime && !Number.isNaN(parseInt(configuredIdleTime)) ? parseInt(configuredIdleTime) : 300;

    const earlyCheckFactorForHeartbeat: number =
        configuredEarlyCheckTime && !Number.isNaN(parseFloat(configuredEarlyCheckTime))
            ? parseFloat(configuredEarlyCheckTime)
            : 0.75;

    const config: WLOverridingConfig = {
        shellConfigs: {
            FIND_OUT_MORE_URL: appConfigs.get(ConfigKey.FIND_OUT_MORE_URL, null),
            CHAT_WIDGET_ASSET_MANIFEST: appConfigs.get(ConfigKey.CHAT_WIDGET_ASSET_MANIFEST),
            BROWSER_VERSIONS: appConfigs.get(ConfigKey.BROWSER_VERSIONS),
            AKAMAI_IMAGE_URL: appConfigs.get(ConfigKey.AKAMAI_IMAGE_URL)
        },
        wlServiceConfig: {
            gbd: { LibraryUrl: appConfigs.get(ConfigKey.GBD_URL) },
            bc: { LibraryUrl: appConfigs.get(ConfigKey.BIOCATCH_URL), configValue: { customerBrand: 'wlEdge' } },
            iv: { LibraryUrl: appConfigs.get(ConfigKey.IOVATION_URL) },
            interaction: {
                endpoint: appConfigs.get(ConfigKey.FRAUD_INTERACTION_API) as string,
                sessionEndpoint: appConfigs.get(ConfigKey.FRAUD_SESSION_API) as string
            },
            appDRUM_app_environment: appConfigs.get(ConfigKey.APPDRUM_APP_ENVIRONMENT),
            fhp: {
                LibraryUrl: `${appConfigs.get(ConfigKey.FHP_LIBRARY_URL)}/${appConfigs.get(
                    ConfigKey.FHP_LIBRARY_NAME
                )}`,
                configValue: {
                    backUrl: appConfigs.get(ConfigKey.FHP_BACK_URL),
                    cid: appConfigs.get(ConfigKey.FHP_CHANNEL_ID)
                }
            },
            flags: {
                clientID: appConfigs.get(ConfigKey.LAUNCH_DARKLY_CLIENT_ID),
                apiKey: appConfigs.get(ConfigKey.LAUNCH_DARKLY_API_KEY),
                initialData: {}
            }
        },
        wlAuthConfig: {
            environment: appConfigs.get(ConfigKey.AUTH_ENVIRONMENT, 'prod'),
            clientId: appConfigs.get(ConfigKey.AUTH_CLIENT_ID),
            scopes: appConfigs.get(ConfigKey.AUTH_SCOPE)
        },
        appDConfig: {
            scriptUrl: appConfigs.get(ConfigKey.APPDRUM_SCRIPT_URL),
            appKey: appConfigs.get(ConfigKey.APPDRUM_APP_KEY),
            adrumExtUrlHttp: appConfigs.get(ConfigKey.APPDRUM_EXT_URL_HTTP),
            adrumExtUrlHttps: appConfigs.get(ConfigKey.APPDRUM_EXT_URL_HTTPS),
            beaconUrlHttp: appConfigs.get(ConfigKey.APPDRUM_BEACON_URL_HTTP),
            beaconUrlHttps: appConfigs.get(ConfigKey.APPDRUM_BEACON_URL_HTTPS),
            xdEnable: appConfigs.get(ConfigKey.APPDRUM_XDENABLE) === 'true',
            spa: appConfigs.get(ConfigKey.APPDRUM_SPA) === 'true'
        },
        navBaseUrl: appConfigs.get(ConfigKey.NAV_MANAGER_BASE_URL),
        dafEndPoint: appConfigs.get(ConfigKey.DAF_ENDPOINT),
        dafAuthRevokeEndPoint: appConfigs.get(ConfigKey.DAF_REVOKE_ENDPOINT),
        appDRum_BSN: appConfigs.get(ConfigKey.APPDRUM_BSN, ''),
        keepAliveConfig: {
            idleTime,
            events: events ? events.split(',') : ['mousemove', 'mousedown', 'keypress', 'touchmove'],
            heartBeatEndPoint: appConfigs.get(ConfigKey.HEARTBEAT_URL),
            earlyCheckFactorForHeartbeat
        },
        channelName: appConfigs.get(ConfigKey.CHANNEL_NAME, NABEDGE),
        brand: {
            themeName: appConfigs.get(ConfigKey.BRAND_THEME, 'nab'),
            id: appConfigs.get(ConfigKey.BRAND_ID, 'nab')
        },
        authStrategies: [
            new CustomDAFUsernamePwdStrategy(
                {
                    appConfig: identityMiniAppConfig,
                    assetManifestUrl: appConfigs.get(ConfigKey.DAF_ASSET_MANIFEST),
                    getHostElement: getLoginMiniappHostELement,
                    onSuccess: () => console.info('Login Success'),
                    onFailure: (e) => console.info('Login failure', e)
                },
                true
            ),
            new DafTransactionStepUpAuthStrategy(transactionStepUpAuthStrategyConfig),

            new DafStepUpAuthStrategy(stepUpAuthConfig),
            new ShellSeedTokenProviderWrapperStrategy(
                async () => Promise.resolve(sessionStorage.getItem('bearerToken')),
                {
                    context: 'external',
                    tokenType: 'Bearer',
                    tokenSource: 'DAF'
                }
            ),
            new DafOnboardingAuthStrategy(onboardingConfig),
            new DafTokenRefreshStrategy(appConfigs.get(ConfigKey.DAF_ENDPOINT), tokenRefreshConfig),
            new DafTokenMigrationStrategy(appConfigs.get('DAF_ENDPOINT'), tokenMigrationConfig, true)
        ],
        userConfig: {
            org: 'NAB',
            type: 'customer'
        },
        rootContextId: appConfigs.get(ConfigKey.NAV_MANAGER_CONTEXT_ID, 'root'),
        serverBasePath: appConfigs.get(ConfigKey.SHELL_BASE_PATH, '/'),
        baseUrl: appConfigs.get(ConfigKey.BASE_URL, '/'),
        ldClientId: appConfigs.get(ConfigKey.LAUNCH_DARKLY_CLIENT_ID)
    };

    return config;
}

export function generateConfigOverrides(configMgr: IConfigMgr): Record<string, string> {
    const overrides = {};
    if (configMgr.get(ConfigKey.AUTH_ENVIRONMENT) !== 'prod') {
        const queryParams = new URLSearchParams(window.location.search as any);
        const env = (queryParams.get('env') ?? 'empty').toLowerCase();
        const hasEnvQueryParams = ['sit1', 'sit4', 'sit5'].includes(env);

        const configs = hasEnvQueryParams
            ? JSON.stringify({ context: `root-${env}`, environment: env })
            : window.sessionStorage.getItem(CONFIG_STORAGE_KEY);

        if (configs) {
            const defaultTheme = configMgr.get(ConfigKey.BRAND_THEME);
            const defaultBrandId = configMgr.get(ConfigKey.BRAND_ID);
            try {
                const {
                    brandId = defaultBrandId,
                    environment,
                    context,
                    theme = defaultTheme,
                    clientId
                } = JSON.parse(configs);

                Object.keys(ConfigKey).forEach((key) => {
                    if (key in BASE_CONFIG_OVERRIDES) {
                        overrides[key] = BASE_CONFIG_OVERRIDES[key][environment];
                    }
                });

                overrides[ConfigKey.NAV_MANAGER_CONTEXT_ID] = context;
                overrides[ConfigKey.BRAND_THEME] = theme;
                overrides[ConfigKey.BRAND_ID] = brandId;
                overrides[ConfigKey.AUTH_CLIENT_ID] = clientId;
            } catch {
                console.error('Failed to parse config overrides');
            } finally {
                window.sessionStorage.removeItem(CONFIG_STORAGE_KEY);
            }
        }
    }

    return overrides;
}
